video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }

  h1 {
    text-shadow: 7px 7px rgb(9, 17, 49);
  }

  body {
      text-align: center;
  }